<template>
  <div class="teach-info-container">
   <div class="inner-container">
      <van-field
      class="zhihui"
      required
      v-model.trim="rightInfoDto.name"
      label="知识产权名称"
      maxlength="50"
      placeholder="请输入知识产权名称"
    />
    <div class="upload-photo-box">
      <van-field required name="uploader" label="知识产权扫描件">
        <template #input>
          <div class="photo_box">
            <div
              class="my_photo"
              v-for="(item, index) in rightInfoDto.urlList"
              :key="index"
            >
              <!-- <img :src="userInfo.idPhoto" /> -->
              <img
                src="../assets/images/delete-icon.png"
                alt=""
                class="del-icon"
                @click="delTeachImg(index)"
              />
              <div class="tips">
                <img :src="item.url" class="re_uploadImg" />
              </div>
            </div>
            <div class="file_box">
              <div class="upload-icon">
                <img src="../assets/images/upload-icon.png" alt="" />
                <span>上传图片</span>
              </div>
              <input
                type="file"
                id="photoFile"
                class="file"
                ref="file"
                @change="readFile($event, 2)"
                accept="image/*"
              />
            </div>
          </div>
        </template>
      </van-field>
    </div>
    
   </div>
   <div class="apply-btn" @click="saveInfo">保存</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightInfoDto: {
        urlList: [],
      },
      activeId: 0 //去重  增加id  编辑
    };
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0; // 滚动条返回顶部
    this.rightInfoDto = this.$store.state.pages.knowItemInfo
  },
  methods: {
    saveInfo() {
      let {name, urlList} = this.rightInfoDto
      if(!name){this.$toast.fail('请输入知识产权名称') 
      return}
      this.$emit("knowInfo", this.rightInfoDto);
      if(!urlList.length){this.$toast.fail('请上传知识产权扫描件')
      return}
      this.$store.commit("getAddKnowState", false);
    },
    readFile(e) {
      console.log(this.rightInfoDto);
      let { urlList } = this.rightInfoDto
      let file = e.target.files[0];
      var formData = new FormData();
      formData.append("file", file);
      if (file.size / 1024 / 1024 > 5) {
        this.$notify({ type: "warning", message: "上传图片大小不超过5M" });
        return;
      }
      console.log('上传图片',urlList,name);
      this.$api.rensheUsercenter.uploadPicture(formData).then((res) => {
          if (urlList.length < 5) {
            urlList.push({
              url:res.data
            });
            return;
          }
          this.$toast.fail("最多上传5张资格证明");
      });
    },
    delTeachImg(index){
      this.rightInfoDto.urlList.splice(index, 1);
    }
  },
};
</script>

<style lang="less" scoped>
.teach-info-container {
  background-color: #f7f7f7;
  height: 100vh;
  .upload-photo-box {
    /deep/.van-cell {
      flex-direction: column;
      align-items: flex-start;
    }
    .file_box {
    //   margin: 32px 0;
    }
  }
  .photo_box{
        display: flex;      
        flex-wrap: wrap;
        width: 690px;
        margin-top: 32px;
        box-sizing: border-box;
        margin-left: -10px;
        .my_photo{
            margin-right: 22px;
            margin-bottom: 40px;
            width: 156px;
            height: 156px;
            position: relative;
            &:nth-child(4){
                margin-right: 0;
            }
            
            .del-icon{
                width: 48px;
                height: 48px;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(30%, -30%);
            }
            // &:nth-child(4n){
            //     margin-left: 0;
            // }
            .tips{
                width: 156px;
                height: 156px;
                img{
                    width: 100%;
                    height: 100%
                }
            }
        }
    }
  .file_box {
    margin-bottom: 40px;
    width: 156px;
    height: 100%;
    position: relative;
    .upload-icon {
      width: 156px;
      height: 156px;
      background: #f9f9f9;
      border-radius: 8px;
      border: 1px solid #c9c9c9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: 400 22px PingFangSC-Regular, PingFang SC;
      color: #333;
      img {
        width: 46px;
        height: 42px;
        margin-bottom: 10px;
      }
    }
    input {
      width: 156px;
      height: 156px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
  .apply-btn {
    border-radius: 44px;
    height: 88px;
    background: #316FFF;
    font-family: 500 32px PingFangSC-Medium, PingFang SC;
    color: #ffffff;
    line-height: 88px;
    text-align: center;
    margin: 32px 30px;
  }


  .inner-container{
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #fff;
  }

  /deep/.van-field__label{
    padding-left: 5px;
    box-sizing: border-box;
  }
  /deep/.van-cell--required::before{
  left: 15px;
}
/deep/.van-field__control{
  text-align: right;
}
}
</style>
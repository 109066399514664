<template>
  <div class="resourceInstitutionsIn">
    <van-nav-bar class="nav_bar" :title="!$store.state.pages.showAddKnowState?'教学资源合作机构入驻申请':'知识产权信息'" placeholder>
      <template #left>
        <van-icon name="arrow-left" size="20" @click="onClickLeft" />
        <van-icon name="wap-home-o" size="20" @click="goHome" />
      </template>
    </van-nav-bar>
    <div v-show="!$store.state.pages.showAddKnowState">
    <van-form
      ref="validateForm"
      validate-first
      input-align="right"
      :show-error="false"
      :show-error-message="false"
    >
      <van-field
        required
        rows="1"
        autosize
        type="textarea"
        v-model.trim="formData.name"
        name="用户名"
        label="机构名称"
        placeholder="请输入机构名称"
        maxlength="40"
      />

      <van-field
        class="zhihui"
        required
        v-model.trim="formData.userName"
        label="联系人"
        placeholder="请输入联系人姓名"
        maxlength="30"
      />
      <van-field
        class="zhihui"
        required
        type="number"
        maxlength="11"
        v-model.trim="formData.phone"
        label="联系电话"
        placeholder="请输入联系电话"
      />
      <van-field
        class="zhihui"
        required
        v-model.trim="formData.authCode"
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #input>
          <input
            type="text"
            class="auth-input"
            v-model.trim="formData.authCode"
          />
          <div class="auth-code" @click="getAuthCode">
            {{ Countdown > 0 ? `${Countdown}s` : "获取验证码" }}
          </div>
        </template>
      </van-field>
         <van-field
        class="zhihui"
        required
        type="text"
        maxlength="40"
        v-model.trim="formData.email"
        label="邮箱"
        placeholder="请输入邮箱"
      />
      <van-field
        class="zhihui"
        required
        v-model.trim="formData.emailAuthCode"
        label="验证码"
        placeholder="请输入验证码"
      >
        <template #input>
          <input
            type="text"
            class="auth-input"
            v-model.trim="formData.emailAuthCode"
          />
          <div class="auth-code" @click="getAuthCodeEmail">
            {{ CountdownEmail > 0 ? `${CountdownEmail}s` : "获取验证码" }}
          </div>
        </template>
      </van-field>
      <van-field
        readonly
        required
        clickable
        name="area"
        :value="value"
        label="地址"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="cityList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        class="zhihui"
        required
        v-model.trim="formData.addressDetail"
        label="详细地址"
        maxlength="50"
        placeholder="请输入详细地址"
      />
      <van-field
        class="zhihui"
        required
        v-model.trim="formData.organCode"
        label="统一社会信用代码"
        maxlength="18"
        placeholder="请输入统一社会信用代码"
      />
      <div class="license-box upload-photo-box">
        <van-field required name="uploader" label="营业执照副本">
          <template #input>
            <div class="photo_box">
              <div class="my_photo" v-if="formData.businessLicenseImage">
                <!-- <img :src="userInfo.idPhoto" /> -->
                <img
                  src="../assets/images/delete-icon.png"
                  alt=""
                  class="del-icon"
                  @click="delLicenseImg"
                />
                <div class="tips">
                  <img
                    :src="formData.businessLicenseImage"
                    class="re_uploadImg"
                  />
                </div>
              </div>
              <div class="file_box" v-else>
                <div class="upload-icon">
                  <img src="../assets/images/upload-icon.png" alt="" />
                  <span>上传图片</span>
                </div>
                <input
                  type="file"
                  id="photoFile"
                  class="file"
                  ref="file"
                  @change="readFile($event, 1)"
                  accept="image/*"
                />
              </div>
            </div>
          </template>
        </van-field>
      </div>
      <van-field class="zhihui" required label="知识产权信息">
        <template #input>
          <div class="auth-code" @click="addTeacherInfo">
            {{ '+ 添加' }}
          </div>
        </template>
      </van-field>
				<div class="teacher-info-box" v-if="formData.rightInfoDto && formData.rightInfoDto.length">
					<div class="teach-item" v-for="(item, index) in formData.rightInfoDto" :key="index">
						<span class="teach-name">{{ item.name }}</span>
						<span class="option-box">
							<i class="edit" @click="editTeachInfo(item)">编辑</i>
							<i class="del" @click="delTeachInfo(index)">
								<img src="../assets/images/delete.png" alt="" />
							</i>
						</span>
					</div>
				</div>
      <div class="license-box-letter upload-photo-box">
        <van-field required name="uploader" label="承诺函">
          <template #input>
            <div class="photo_box">
              <div class="my_photo" v-if="formData.letterList.length>0">
                <!-- <img :src="userInfo.idPhoto" /> -->
                <img src="../assets/images/delete-icon.png" alt="" class="del-icon" @click="delLetterImg" />
                <div class="tips">
                  <img :src="formData.letterList[0].url" class="re_uploadImg" />
                </div>
              </div>
              <div class="file_box" v-else>
                <div class="upload-icon">
                  <img src="../assets/images/upload-icon.png" alt="" />
                  <span>上传图片</span>
                </div>
                <input type="file" id="photoFile" class="file" ref="file" @change="readFileImg($event, 1,'letterList')" accept="image/*" />
              </div>
            </div>
          </template>
        </van-field>
        <div class="demoLetter" label="承诺函">
          <p>承诺函示例</p>
          <div class="right-box">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png" alt="" @click="viewDemo">
            <!-- <span @click="download">下载承诺函模板</span> -->
          </div>
        </div>
      </div>
    </van-form>
    <div class="handle_btn">
      <div class="commitment-box">
        <van-checkbox v-model="checked" />
        <p>
          我同意并遵守<span @click="checkInstitutions"
            >《机构入驻合作协议》</span
          >
          并保证符合承诺书内容
        </p>
      </div>
      <div class="apply-btn" ref="buttonRef" @click="apply">申请</div>
    </div>
  </div>
    <AddKnowInfo v-if="$store.state.pages.showAddKnowState" @knowInfo="getTeachInfo" />
    <van-popup
      v-model="isShowInstitutionInProtocol"
      round
      position="bottom"
      title="测试"
      :style="{ height: '70%' }"
    >
      <div class="institutionInProtocol-content">
        <div class="popup-header">
          <div class="title">{{ "《机构入驻合作协议》" }}</div>
          <img src="../assets/images/delete.png" alt="" @click="hidePopup" />
        </div>
        <InstitutionInProtocol />
      </div>
    </van-popup>
    <van-popup
      v-model="show1"
      round
      position="bottom"
      :style="{ height: '75%' }"
    >
      <div class="popup_body">
        <div class="header">
          <span class="protocol-title">《机构入驻合作协议》</span>
          <span @click="show1 = false">
            <svg-icon icon-class="close" class-name="close"></svg-icon>
          </span>
          <div class="line"></div>
        </div>
        <div class="line"></div>
        <div class="content">
          <InstitutionInProtocol />
        </div>
        <div class="footer">
          <div class="line"></div>
          <div class="commitment-box">
            <van-checkbox v-model="checked" />
            <p>我同意并遵守<span>《机构入驻合作协议》</span></p>
          </div>
          <div class="apply-btn" @click="show1 = false">确认</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import InstitutionInProtocol from "./institutionInProtocol";
import { checkForm } from "../component/checkform";
import AddKnowInfo from '../component/AddKnowInfo'
import { ImagePreview } from 'vant';
import { exportMethod } from "@/utils/download";
import URL from "@/config/url";

export default {
  name: "institutionsIn",
  mounted() {
    this.getRegionList();
  },
  components: {
    InstitutionInProtocol,
    AddKnowInfo
  },
  data() {
    return {
      isShowInstitutionInProtocol: false,
      previewImg:false,
      formData: {
        name: "",
        addressDetail: "",
        phone: "",
        province: "",
        city: "",
        district: "",
        businessLicenseImage: "",
        authCode: "",
        emailAuthCode:'',
        email:'',
        regionCode: "",
        organCode: "", //社会信用代码
        letterList:[],
        rightInfoDto:[]
      },
      activeId: 0,
      // 本次新增
      switchChecked: 1,
      value: "",
      showArea: false,
      cityList: {
        province_list: {},
        city_list: {},
        county_list: {},
      }, // 数据格式见 Area 组件文档
      showPicker: false,
      checked: false, // 是否同意承诺书
      imgUrlList: [], // 上传图片数组
      teachImgUrlList: [], //培训教师资格证图片
      Countdown: 0, // 获取验证码倒计时
      CountdownEmail:0,
      isPhone: /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/,
      isEmail:/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
      reg: /^[A-Z0-9]{18}$/, // 信用代码验证
      show1: false,
    };
  },
  watch: {
    formData: {
      handler(newVal) {
        let { success } = checkForm(newVal);
        if (this.checked && success) {
          this.$refs.buttonRef.style.backgroundColor = "#316FFF";
        } else {
          this.$refs.buttonRef.style.backgroundColor = "#CBCED5";
        }
      },
      deep: true,
    },
    checked(newVal) {
      let { success } = checkForm(this.formData);
      if (newVal && success) {
        this.$refs.buttonRef.style.backgroundColor = "#316FFF";
      } else {
        this.$refs.buttonRef.style.backgroundColor = "#CBCED5";
      }
    },
    'formData.rightInfoDto.length'() {
			this.formData.rightInfoDto.map((item, indexs) => {
        this.formData.rightInfoDto.map((ite, index) => {
          if (ite.id == item.id && indexs != index) {
            this.formData.rightInfoDto.splice(index, 1)
          }
        })
      })
		},
  },
  destroyed() {
		this.$store.commit('getKnowItemInfo', {
			id: this.activeId++,
			urlList: [],
		})
		this.$store.commit('getAddKnowState', false)
	},
  methods: {
     is_weixin() {
        var ua = window.navigator.userAgent.toLowerCase(); 
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { 
            console.log('微信浏览器'); 
            return true;
        } else { 
            console.log("不是微信浏览器"); 
            return false;
        } 
    },
    viewDemo(){
			ImagePreview({
        images:['https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png']
      });
    },
    download(){
			let myObj = {
				method: "get",
				url: `${URL.API}/saas/v1/branchApplyEnclosure/download`,
			};
			exportMethod(myObj,true);
		},
    delLetterImg(index) {
			this.formData.letterList.splice(index, 1)
    },
    readFileImg(e, type,imgType) {
			// type 1 单张  2 五张
			let { letterList,materialList,deviceList } = this.formData
			let file = e.target.files[0]
			var formData = new FormData()
			formData.append('file', file)
			if (file.size / 1024 / 1024 > 5) {
				this.$notify({ type: 'warning', message: '上传图片大小不超过5M' })
				return
			}
			this.$api.rensheUsercenter.uploadPicture(formData).then((res) => {
				if (imgType == 'letterList') {
					this.formData.letterList = [{
						url:res.data,
						name:'',
					}]
				}
			})
		},
    addTeacherInfo() {
			if (this.formData.rightInfoDto.length > 30) {
				this.$toast.fail('至多可添加5条知识产权信息')
				return
			}
			this.$store.commit('getKnowItemInfo', {
				id: this.activeId++,
				urlList: [],
			})
			this.$store.commit('getAddKnowState', true)
    },
    // 获取添加的知识产权信息
		getTeachInfo(val) {
			this.formData.rightInfoDto.push(val)
    },
    // 删除知识产权信息
		delTeachInfo(index) {
			this.formData.rightInfoDto.splice(index, 1)
		},
		// 编辑知识产权信息
		editTeachInfo(item) {
      console.log(item);
			this.$store.commit('getKnowItemInfo', item)
      this.$store.commit('getAddKnowState', true)
		},
    getRegionList() {
      this.$api.usercenter.getTree({ type: 1 }).then((res) => {
        if (res.success && res.data) {
          let filterArr = res.data.filter((item) => {
            return item.code.length === 2 && item.children;
          });
          let uniqueArr = Array.from(new Set(filterArr));
          // 切换成vant省市组件格式
          uniqueArr.map((item) => {
            this.cityList.province_list[item.code] = item.name;
            item.children.map((province) => {
              this.cityList.city_list[province.code] = province.name;
              (province.children || []).map((county) => {
                this.cityList.county_list[county.code] = county.name;
              });
            });
          });
        }
      });
    },
    onClickLeft() {
      if (this.$store.state.pages.showAddKnowState) {
        this.$store.commit("getAddKnowState", false);
        return;
      }
      this.$router.go(-1);
    },
    goHome() {
      // 回首页
      console.log("--->回到首页");
      this.$router.push("/home");
    },
    // 省市选择
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.formData.regionCode = values[2].code;
      this.showArea = false;
    },

    // 获取验证码
    getAuthCode() {
      if (!this.formData.phone) {
        this.$toast.fail("联系方式不能为空");
        return;
      }
      if (!this.isPhone.test(this.formData.phone)) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      if (this.Countdown > 0) return;
      this.Countdown = 60;
      this.setCountdown();
      let data = { account: this.formData.phone, type: 2 };
      this.$api.usercenter.getAuthCodePublic({ data });
    },
    // 验证码倒计时
    setCountdown() {
      this.CountdownInterval = setInterval(() => {
        this.Countdown--;
        if (this.Countdown <= 0) {
          clearInterval(this.CountdownInterval);
        }
      }, 1000);
    },
    getAuthCodeEmail() {
      if (!this.formData.email) {
        this.$toast.fail("邮箱不能为空");
        return;
      }
      if (!this.isEmail.test(this.formData.email)) {
        this.$toast.fail("请输入正确的邮箱账号");
        return;
      }
      if (this.CountdownEmail > 0) return;
      this.CountdownEmail = 60;
      this.setCountdownEmail();
      let data = { account: this.formData.email, type: 2 };
      this.$api.usercenter.getAuthCodePublic({ data });
    },
    // 验证码倒计时
    setCountdownEmail() {
      this.CountdownIntervalEmail = setInterval(() => {
        this.CountdownEmail--;
        if (this.CountdownEmail <= 0) {
          clearInterval(this.CountdownIntervalEmail);
        }
      }, 1000);
    },
    // 查看机构入驻协议
    checkInstitutions() {
      //   this.isShowInstitutionInProtocol = true;
      this.show1 = true;
    },
    // 点击申请
    apply(value) {
      if (!this.checked) {
        this.$toast.fail("请勾选协议！");
        return;
      }
      if (!this.isPhone.test(this.formData.phone)) {
        this.$toast.fail("联系方式格式错误");
        return;
      }
      if (!this.reg.test(this.formData.organCode)) {
        this.$toast.fail("统一社会信用代码18位数字或大写字母组成");
        return;
      }
      let { success, title } = checkForm(this.formData);
      if (!success) {
        this.$toast.fail(`${title}不能为空`);
        return;
      }
      this.$api.rensheUsercenter
        .saveResourceCooperation(this.formData)
        .then((res) => {
          if (res.success) {
            this.$toast(
              "提交成功，请等待审核，审核结果将以短信发送，请注意查收"
            );
            this.$router.go(-1);
          }
        });
    },
    // 上传图片
    readFile(e, type) {
      let { businessLicenseImage, trainTeacherImage } = this.formData;
      let file = e.target.files[0];
      var formData = new FormData();
      formData.append("file", file);
      if (file.size / 1024 / 1024 > 5) {
        this.$notify({ type: "warning", message: "上传图片大小不超过5M" });
        return;
      }
      this.$api.rensheUsercenter.uploadPicture(formData).then((res) => {
        if (type == 1) {
          this.formData.businessLicenseImage = res.data;
        } else {
          if (trainTeacherImage.length < 5) {
            trainTeacherImage.push(res.data);
            return;
          }
          this.$toast.fail("传的太多了");
        }
      });
    },
    // 删除图片
    delLicenseImg(e) {
      this.formData.businessLicenseImage = "";
    },
    // 关闭协议弹窗
    hidePopup() {
      this.isShowInstitutionInProtocol = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/css/resourceInstitutionsIn.less";
/deep/.van-field__label{
    padding-left: 5px;
    box-sizing: border-box;
  }
/deep/.van-cell--required::before{
  left: 15px;
}
.demoLetter::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16PX;
    bottom: 0;
    left: 16PX;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
}
.license-box-letter{
	/deep/.van-cell::after{
		border:none;
	}
}
.demoLetter{
  position:relative;
  background: #fff;
  padding:24px 30px 24px;
  font-size: 30px;
  .right-box{
    display: flex;
    align-items: center;
  }
	img {
    width:156px;
    margin-top:24px;
  }
  span{
    color:#3860FF;
    margin-left: 24px;

  }
}
</style>
